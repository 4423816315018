import React from 'react';
import { graphql } from 'gatsby';
import TitlePage from '../components/TitlePage';
import PageItem from '../components/PageItem';
import SEO from '../components/seo';
import Container from '../components/Container';
import RichText from '../components/RichText';
import Accordion from '../components/Accordion';
import BackLink from '../components/BackLink';
import Button from '../components/Button';
import TypeFormButton from '../components/TypeformButton';
import Helmet from 'react-helmet';
import useResponsiveImage from '../components/useResponsiveImage';
import * as S from './page.styled';


const Page = props => {
  const locale = props.data.markdownRemark.fields.locale;
  const folder = props.data.markdownRemark.frontmatter.folder;
  const pageContent = props.data.markdownRemark;
  const pageList = props.data.allMarkdownRemark.edges;

  return (
    <>
      <SEO
        lang={locale}
        title={pageContent.frontmatter.title}
        description={pageContent.frontmatter.description}
        image={pageContent.frontmatter.image}
      />

     { pageContent.frontmatter.noIndex &&
      <Helmet>
         <meta name="robots" content="noindex,nofollow"/>
      </Helmet>
      }



    {

    ( pageContent.frontmatter.featuredImage?.image && pageContent.frontmatter.featuredImage?.showImage ) &&

    <S.SectionAlt>
      <Container>
      <S.PostWrapper>



          <S.PostImgWrapper>
            <S.Circle>
            <S.PostImg
              fluid={useResponsiveImage(pageContent.frontmatter.featuredImage.image)}
              alt={pageContent.frontmatter.title}
            />
            </S.Circle>
          </S.PostImgWrapper>
        <S.PostInfo>
          <TitlePage text={pageContent.frontmatter.title} />

              <S.PageIntro>{pageContent.frontmatter.description}</S.PageIntro>

              { 
               (
                 pageContent.frontmatter.button?.typeFormId &&
                 pageContent.frontmatter.button?.text
                ) &&

                <TypeFormButton
                  typeFormId={pageContent.frontmatter.button.typeFormId}
                  typeFormVar={pageContent.frontmatter.button.typeFormVar}
                  typeFormVarValue={pageContent.frontmatter.button.typeFormVarValue}
                  text={pageContent.frontmatter.button.text}
                />
               }

               {
                (
                  pageContent.frontmatter.button?.text &&
                  pageContent.frontmatter.button?.url &&
                  !pageContent.frontmatter.button?.typeFormId 
                ) &&

                  <Button 
                    url={pageContent.frontmatter.button.url} 
                    isExternal={pageContent.frontmatter.button.urlIsExternal}
                    text={pageContent.frontmatter.button.text}
                    lang={locale} />
               }

                  
              </S.PostInfo>
      </S.PostWrapper>
      </Container>
    </S.SectionAlt>

    }



    <S.Section>
      <Container>

          {( pageContent.frontmatter.featuredImage?.image && pageContent.frontmatter.featuredImage?.showImage ) ||
          <>
            <TitlePage text={pageContent.frontmatter.title} />
            <S.PageIntro>{pageContent.frontmatter.description}</S.PageIntro>
          </>
          }

          { pageContent.frontmatter.menu &&
          <S.Menu>

            {
              pageContent.frontmatter.menu.map(
                ({
                  text,
                  url
                },index)=>(

                  <li key={index}>
                    <S.MenuLink to={url} lang={locale}>
                        {text}&nbsp;→
                    </S.MenuLink>
                  </li>
          
                ) 
              )
            }
          </S.Menu> 
          }

          {
            pageContent.frontmatter.detailImage &&
            <S.PostImg
            fluid={useResponsiveImage(pageContent.frontmatter.detailImage, false)}
            alt={pageContent.frontmatter.title}
            />
          }

          {
            pageContent.html &&
            <RichText dangerouslySetInnerHTML={{ __html: pageContent.html }} />
          }  

          {folder && 
            <BackLink folder={folder} lang={locale}></BackLink>
          }


     </Container>
    </S.Section>



    { pageContent.frontmatter.index?.folderToIndex &&
    <S.SectionAlt>
    <Container>
      
      <S.Grid>
      {pageList.map(
          ({
            node: {
              frontmatter: {
                title,
                description,
                folder,
                featuredImage
              },
              fields: { slug },
            },
          }) => (
            (folder === pageContent.frontmatter.index.folderToIndex && 
              featuredImage?.image ) &&
              <PageItem
                lang={locale}
                slug={`/${folder}/${slug}`}
                title={title}
                description={description}
                image={featuredImage.image}
                key={slug}
              />
          ),
        )}
        </S.Grid>
      </Container>
  </S.SectionAlt> 
  }  


    {
    
    pageContent.frontmatter.accordions ?
      <S.SectionAlt>
        <Container>
        {
          pageContent.frontmatter.accordions.map(
            ({
              accordion
            },index)=>(

              <Accordion key={index} content={accordion}></Accordion>
      
            ) 
          )
        }
        </Container>
      </S.SectionAlt> 
    : null
    }

    </>
  );
};

export const query = graphql`
  query Page($locale: String!, $title: String!) {

    allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        frontmatter: {
          folder: {
            nin: ["blog", null, ""]
          }
      }
      }, sort: {
        order: [ASC, ASC], 
        fields: [frontmatter___order, frontmatter___title]
      }) {
      edges {
        node {
          frontmatter {
            title
            description
            folder
            featuredImage{
                image
              }
            }
            fields {
              slug
            }
          }
        }
      }

    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      fields {
        locale
      }       
      frontmatter {
        title
        noIndex
        hideTryNow
        description
        folder
        featuredImage{
          image
          showImage
        }
        detailImage
        index {
          folderToIndex
        }
        button {
          text
          url
          urlIsExternal
          typeFormId
          typeFormVar
          typeFormVarValue
        }
        menu {
          text
          url
        }
        accordions {
          accordion {
            title
            description
            items {
              title
              content
            }
          }          
        }
      }
      html
    }
 
}  
  
`;

export default Page;