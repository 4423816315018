import React from 'react';
import useTranslations from '../useTranslations';
import * as S from './styled';



const BackLink = ({lang, folder}) => {

  const {
    backTo,

  } = useTranslations(lang);

  return (

    
    
    <S.BackLink to={`/${folder}/`} lang={lang}>← {backTo}</S.BackLink>

  );
};

export default BackLink;
