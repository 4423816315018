import React from 'react';
import SubTitlePage from '../SubTitlePage';
import RichText from '../RichText';
import AccordionItem from '../AccordionItem';
import {ConvertMarkdownToHtml} from '../../shared/helpers';

import * as S from './styled';

const Accordion = props => {
  const { content } = props;

  return (
    <S.Accordion>

    <SubTitlePage text={content.title}/>

    {
      content.description ?
        <RichText dangerouslySetInnerHTML={{ __html: ConvertMarkdownToHtml(content.description )}} /> : null
    }

    {
      content.items.map(
        ({
          title,
          content
        },index)=>(
          <AccordionItem key={index} title={title} content={content}/>
        ) 
      )
    }

   
    </S.Accordion>
    );
};

export default Accordion;
