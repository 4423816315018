import styled from 'styled-components';
import LocalizedLink from '../components/LocalizedLink';
import media from 'styled-media-query';
import Img from 'gatsby-image';

export const PageWrap = styled.div`
`;

export const PageIntro = styled.p`
  color: var(--text-color);
  font-weight: 600;
  padding-bottom: var(--space);
`;

export const Section = styled.section`
  // background-color: var(--bg-light);
  // border: 1px solid var(--bg-light);
`;

export const SectionAlt = styled.section`
  background-color: var(--bg-light);
  border: 1px solid var(--bg-light);
  margin-top: 1px;
`;


export const Menu = styled.ul`
  margin-bottom: var(--space-lg);
  border-top: 1px solid var(--bg-light);
  li {
    border-bottom: 1px solid var(--bg-light);
  }
`;

export const MenuLink = styled(LocalizedLink)`
  text-decoration: none;
  display: block;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  padding-left: 0;
  padding-right: 6px;
  transition: padding 0.1s ease-in-out;
  &:hover{
    padding-left: 3px;
    padding-right: 3px;
  }
`;

export const IndexItem = styled(LocalizedLink)`
  text-decoration: none;
  display: block;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  padding-left: 0;
  padding-right: 6px;
  transition: padding 0.1s ease-in-out;
  &:hover{
    padding-left: 3px;
    padding-right: 3px;
  }
`;


export const Grid = styled.div`

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-gap: var(--space-sm);

  ${media.greaterThan('small')`
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: var(--space);
  `}    
  ${media.greaterThan('medium')`
    grid-gap: var(--space-lg);
  `}    
`
;
export const PostWrapper = styled.article`
  display: flex;
`;
export const Circle = styled.div `
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  overflow: hidden;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  ${media.greaterThan('small')`
    width: 140px;
    height: 140px;
  `}    
  ${media.greaterThan('medium')`
    width: 180px;
    height: 180px;
  `}  
`
export const PostImg = styled(Img)`
  width: 100%;
  height: 100%;
`;
export const PostImgWrapper = styled.div`
  display: none;
    ${media.greaterThan('medium')`
      display: block;
    `}  
  margin-right: 0;
  ${media.greaterThan('medium')`
    margin-right: var(--space-lg);
  `}      
`;
export const PostImgContentWrapper = styled.div`
  background-color: var(--bg-light);
  padding: var(--space-sm);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
    ${media.greaterThan('medium')`
      display: none;
    `}  
`;


export const PostInfo = styled.div`
  // margin-left: 0;
  // ${media.greaterThan('medium')`
  //   margin-left: var(--space-lg);
  // `}    
`;

export const PostDate = styled.time`
  color: var(--text-light);
  font-size: 1.4rem;
  margin-bottom: var(--space-sm);
  margin-right: var(--space-sm);
  border-right: 1px solid #ddd;
  padding-right: var(--space-sm);
  ${media.greaterThan('large')`
    font-size: 1.6rem;
  `}  
`;
