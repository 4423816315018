import styled from 'styled-components';
import media from 'styled-media-query';

export const AccordionItem = styled.div`
box-shadow: 0 0.2em 0.5em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
border-radius: 5px;
padding: var(--space-sm);
margin: var(--space-sm) 0;
background-color: var(--color-white);
&.active{
    button{
        &:before{
            transform: rotate(90deg);
        }
    }
    .content{
        display: block;
    }
    .header{
        border-bottom: 2px solid var(--gray-extra-light);
        padding-bottom: var(--space-sm);
    }
}

.content{
    display: none;
    margin: 0 44px;
    padding-top: var(--space-lg);
    ${media.lessThan('medium')`
        margin: 0;
  `}
}

.header{
    display: flex;
    align-items: center;
    h3{
        font-weight: 600;
        cursor: pointer;
        &:hover{
            color: var(--action-color);
        }
    }
    button{
        border-radius: 3px;
        border: none;
        height: 32px;
        width: 32px;
        min-width: 32px;
        display: block;
        margin-right: 12px;
        background-color: var(--action-color);
        padding: 0;
        position: relative;
        cursor: pointer;
        &:after, &:before{
            content: '';
            display: block;
            height: 12px;
            width: 2px;
            background-color: white;
            position: absolute;
            top: 10px;
            left: 15px;
        }
        &:after{
            transform: rotate(90deg);
        }
        &:before{
            transition: transform 0.1s ease-in-out;
        }
        &:hover{
            background-color: var(--action-color-hover);
        }
    }
}



`;
