import React from 'react';
import { Popup } from 'react-typeform-embed';
import * as S from './styled';

const TypeFormButton = ({typeFormId, typeFormVar, typeFormVarValue, text}) => {

  return (

      typeFormVar && typeFormVarValue ?

      <Popup
        size="88"
        id={typeFormId}
        hidden={{
          [typeFormVar]: typeFormVarValue
        }}
        >
        <S.Button>{text}</S.Button>
      </Popup> 
      
      :

      <Popup size="88" id={typeFormId}>
        <S.Button>{text}</S.Button>
      </Popup>

  );
};

export default TypeFormButton;
