import React from 'react';
import * as S from './styled';

const Button = ({lang, url, text, isExternal}) => {

  return (

    isExternal ?
      <S.ButtonExternal href={url} target="_blank" rel="noopener noreferrer">{text}</S.ButtonExternal>
    :
      <S.ButtonInternal to={url} lang={lang}>{text}</S.ButtonInternal>
  );
};

export default Button;
